import axios from "axios";
import { authInterceptor } from "./index";

const REAL_ESTATE_API_URL_V2 =
  process.env.VUE_APP_REAL_ESTATE_API_GATEWAY_URL + "/api/v2";

const authorizedReq = axios.create();

const merchantHeaderInterceptor = [
  (config) => {
    config.headers["X-Merchant"] = process.env.MERCHANT_ID || "";
    return config;
  },
  (error) => Promise.reject(error),
];

authorizedReq.interceptors.request.use(...authInterceptor);
authorizedReq.interceptors.request.use(...merchantHeaderInterceptor);

export const getRealEstatePermissions = () =>
  authorizedReq.get(REAL_ESTATE_API_URL_V2 + "/self-data");
